@import "settings";
@import "mixins";

html { background: #fff; }
body { background: #fff; }
html, body, input, textarea, select {
  color: #5c5f69;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
	font-weight: 400;
  line-height: normal;
}

*, *:after, *:before { @include border-box(); }

.content-wrapper {
  margin: 0 auto;
  overflow: hidden;
  &:after{
    clear: both;
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
  }
}

a {
  color: #000;
  outline: 0;
  text-decoration:none;
  &:hover {
    color: $color-links;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

strong { font-weight: 700; }
p { margin-bottom: 20px; }
ul, ol { margin: 0; padding: 0; }
li { list-style-type: none; }

hr {
	@include content-box();
  height: 0;
}

input[type="checkbox"], input[type="radio"] {
	@include border-box();
  padding: 0;
}

/*****************************************************************************
                                 Headlines
*****************************************************************************/

h1, h2, h3, h4, h5, h6 {
  display: block;
  margin: 0;
}

/*****************************************************************************
                                CSS Clasess
*****************************************************************************/

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix { display: inline-block; }

html[xmlns] .clearfix { display: block; }

* html .clearfix { height: 1%; }

.cleared {
  display: block;
  clear: both;
  line-height: 0;
  height: 0;
}

.rs {
  float: right;
}
.ls {
  float: left;
}

/*****************************************************************************
                                 Error 404
*****************************************************************************/
#info-error {
  text-align: center;
  margin: auto;
  a.logo {
    margin: 30px auto 80px;
    display:block;
    width: 120px;
    height: 137px;
    background: url(../img/logo.png) no-repeat 0 0;
    background-size: 100% auto;
    text-indent: -9999px;
    @include transition(all 0.4s ease);
  }
  h3 {
    margin: 0px 0 31px 0;
    color: $color-links;
    line-height: 190px;
    font-size: 200px;
    font-weight: 900;
  }
  h4 {
    margin-bottom: 14px;
    color: #121212;
    line-height: 54px;
    font-size: 44px;
    font-weight: 900;
  }
  p {
    margin-bottom: 36px;
    color: #121212;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
  }
  a.btn-back {
    display: inline-block;
    padding: 0 32px;
    background: $color-links;
    border: 2px solid $color-links;
    color: #fff;
    line-height: 63px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    @include border-radius(3px);
    @include transition(all 0.4s ease);
    &:hover {
      background: none;
      color: $color-links;
    }
  }
  .footer {
    margin-top: 100px;
    padding: 0 30px;
    padding-bottom: 0px;
    color: #999;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 20px;
    a {
      color: #4e4c4c;
      @include transition(all 0.4s ease);
      &:hover {
        color: $color-links;
      }
    }
  }
}
@media only screen and (max-width: 1429px) {
  #info-error {
    h3 {
      margin-bottom: 0;
      font-size: 170px;
    }
  }
}
@media only screen and (max-width: 1199px) {
  #info-error {
    a.logo {
      margin-bottom: 60px;
    }
    h3 {
      font-size: 150px;
      line-height: 150px;
    }
    h4 {
      font-size: 30px;
    }
  }
  #info-error .footer span {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  #info-error {
    a.logo {
      width: 100px;
      height: 114px;
    }
    h3 {
      margin-bottom: 0;
      font-size: 90px;
      line-height: 90px;
    }
    h4 {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: 575px) {
  #info-error {
    a.logo {
      margin: 20px auto 30px;
    }
    h3 {
      font-size: 80px;
      line-height: 80px;
    }
    h4 {
      font-size: 20px;
      line-height: inherit;
    }
    a.btn-back {
      padding: 0 25px;
      line-height: 58px;
    }
  }
}
/*****************************************************************************
                                 Subpage Name
*****************************************************************************/
.subpage-name {
  height: 178px;
  background: url(../img/sb-title.jpg) no-repeat top center;
  background-size: 100% 100%;
  .inside {
    padding-top: 60px;
    h2 {
      margin-bottom: 9px;
      color: #fff;
      font-family: 'Jost', sans-serif;
      font-size: 44px;
      line-height: 32px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .breadcrumb-wg {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 6px;
        color: #14542d;
        font-family: 'Jost', sans-serif;
        font-size: 15px;
        font-weight: 400;
        a {
          color: #14542d;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1699px) {
  .subpage-name {
    background-size: 1903px;
  }
}
@media only screen and (max-width: 1429px) {
  .subpage-name {
    height: 127px;
    background-size: 1429px;
    .inside {
      padding-top: 40px;
      h2 {
        margin-bottom: 5px;
        font-size: 34px;
        line-height: 38px;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .subpage-name {
    height: 105px;
    background-size: 1199px;
    .inside {
      padding-top: 30px;
      h2 {
        margin-bottom: 3px;
        font-size: 30px;
        line-height: 34px;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .subpage-name {
    height: 89px;
    background-size: 991px;
    .inside {
      padding-top: 23px;
      h2 {
        margin-bottom: 0;
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .subpage-name {
    display: flex;
    align-items: center;
    height: 69px;
    background-size: 767px;
    .inside {
      padding-top: 0;
      h2 {
        font-size: 20px;
        line-height: 24px;
      }
      .breadcrumb-wg {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 479px) {
  .subpage-name {
    height: 60px;
    background-size: 660px;
    .inside {
      h2 {
        font-size: 17px;
        line-height: 21px;
      }
    }
  }
}

/*****************************************************************************
                                 Buttons & Links
*****************************************************************************/
a.link {
  display: inline-block;
  color: #999;
  font-weight: 500;
  font-size: 21px;
  line-height: 21px;
  @include transition(all 0.3s ease);
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #999;
    @include transition(all 0.3s ease);
  }
  &:hover {
    color: $color-links;
    &:after {
      background: $color-links;
    }
  }
}
@media only screen and (max-width: 1699px) {
  a.link {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  a.link {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  a.link {
    font-size: 16px;
  }
}

.btn-link {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-right: 28px;
  color: $color-links;
  font-weight: 600;
  font-size: 16px;
  border: 0;
  background: url(../img/icons/arrow-right.png) no-repeat right 7px;
  text-decoration: none;
  @include transition(all 0.3s ease);
  &:after {
    content: '';
    display: block;
    margin-top: 8px;
    width: 100%;
    height: 2px;
    background: $color-links;
    @include transition(all 0.3s ease);
  }
  &:hover {
    color: $color-links;
    text-decoration: none;
    &:after {
      background: $color-links;
    }
  }
}
@media only screen and (max-width: 1429px) {
  .btn-link {

  }
}
@media only screen and (max-width: 1199px) {
  .btn-link {
    font-size: 14px;
    background: url(../img/icons/arrow-right.png) no-repeat right 4px;
    &:after {
      margin-top: 4px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .btn-link {
    padding-right: 26px;
    &:after {
      margin-top: 0;
      height: 1px;
    }
  }
}

.btn {
  cursor: pointer;
  @include border-radius(3px);
  @include transition(all 0.3s ease);
}

.btn-transparent {
  padding: 0 28px;
  line-height: 49px;
  color: #333;
  border: 2px solid #9e9e9e;
  font-size: 15px;
  font-weight: 500;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #9e9e9e;
  }
}

.btn-white {
  padding: 0 25px;
  line-height: 50px;
  color: #333;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: $color-links;
  }
}

.btn-gold {
  padding: 0 68px;
  line-height: 60px;
  color: $color-links;
  border: 3px solid $color-links;
  font-size: 17px;
  font-weight: 600;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: $color-links;
  }
}
@media only screen and (max-width: 1199px) {
  .btn-gold {
    padding: 0 40px;
    line-height: 50px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 479px) {
  .btn-red {
    padding: 0 20px;
    font-size: 14px;
  }
}

.btn-red-2 {
  padding: 0 58px;
  line-height: 57px;
  color: #fff;
  background: $color-links;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  @include border-radius(0px);
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #ff1b3e;
  }
}
@media only screen and (max-width: 1199px) {
  .btn-red-2 {
    padding: 0 40px;
    line-height: 50px;
    font-size: 14px;
  }
}

.btn-blue {
  padding: 0 30px;
  line-height: 44px;
  color: #fff;
  border: 0;
  background-color: #103095;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #017ae1;
  }
}
@media only screen and (max-width: 767px) {
  .btn-blue {
  padding: 0 20px;
  line-height: 44px;
  font-weight: 400;
  }
}

.btn-navy {
  padding: 0 23px;
  line-height: 54px;
  color: #fff;
  border: 0;
  background-color: #1e253a;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #017ae1;
  }
}

.btn-grey {
  padding: 0 10px;
  line-height: 44px;
  color: #fff;
  border: 0;
  background-color: #acb2b8;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #93979b;
  }
}

.btn-green {
  padding: 0 21px;
  line-height: 37px;
  color: #fff;
  border: 0;
  background-color: $color-links;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #15ab67;
  }
}

/*****************************************************************************
                                 Pagination
*****************************************************************************/
ul.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  li {
    margin: 0 3px;
  }
}
.btn-pagination {
  display: block;
  cursor: pointer;
  @include border-radius(3px);
  @include transition(all 0.3s ease);
  padding: 9px 15px;
  line-height: 16px;
  color: #fff;
  border: 0;
  background-color: $color-links;
  font-size: 16px;
  font-weight: 400;
  &:hover, &:focus, &.active {
    color: #fff;
    outline: none;
    background-color: #26c47c;
  }
}
@media only screen and (max-width: 1199px) {
  ul.pagination {
    margin: 25px 0;
  }
}
@media only screen and (max-width: 991px) {
  ul.pagination {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 479px) {
  ul.pagination {
    li.prev {
      .btn-pagination {
        padding: 9px 20px;
        background: $color-links url(../img/icons/prev.png) no-repeat center center;
        text-indent: -9999px;
        &:hover {
          background-color: #7c5623;
        }
      }
    }
    li.next {
      .btn-pagination {
        padding: 9px 20px;
        background: $color-links url(../img/icons/next.png) no-repeat center center;
        text-indent: -9999px;
        &:hover {
          background-color: #7c5623;
        }
      }
    }
  }
}

/*****************************************************************************
                                 Site Map
*****************************************************************************/
ul.site-map {
  padding-left: 35px;
  padding-top: 10px;
  li {
    position: relative;
    list-style-type: none;
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 500;
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 9px;
      left: -23px;
      background-color: $color-links;
      width: 8px;
      height: 8px;
      @include border-radius(50%);
    }
    a {
      color: #040c26;
      &:hover {
        color: $color-links;
      }
    }
    ul {
      padding-left: 30px;
      padding-top: 10px;
      li {
        font-size: 16px;
        font-weight: 300;
        &:after {
          top: 10px;
          left: -15px;
          width: 5px;
          height: 5px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/*****************************************************************************
                                 Article Typography
*****************************************************************************/
article {
  * a {
    color: $color-links;
    &:hover {
      color: #000;
    }
  }
  h2 {
    color: #222;
    font-size: 32px;
    line-height: 42px;
    margin: 0 0 30px;
    font-weight: 600;
  }
  h3 {
    color: #222;
    font-size: 28px;
    margin: 0 0 30px;
    padding-top: 20px;
    font-weight: 600;
  }
  h4 {
    color: #222;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 30px;
    padding-top: 20px;
  }
  h5 {
    color: #222;
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0;
  }
  p {
    margin-bottom: 20px;
    color: #222;
    font-size: 19px;
    line-height: 34px;
    font-weight: 300;
    strong {
      font-weight: 600;
    }
    a {
      color: $color-links;
      &:hover {
        color: $color-links;
        text-decoration: underline;
      }
    }
  }
  ol {
    margin-left: 23px;
    li {
      list-style: decimal;
      padding-left: 12px;
      margin: 0 0 20px;
      color: #222;
      font-size: 19px;
      line-height: 34px;
      font-weight: 300;
    }
    ol {
      padding-top: 10px;
      li {
        list-style-type: lower-latin;
      }
    }
  }
  ul {
    margin-left: 14px;
    margin-bottom: 30px;
    li {
      position: relative;
      display: block;
      padding-left: 19px;
      padding-bottom: 10px;
      color: #222;
      font-size: 19px;
      line-height: 34px;
      font-weight: 300;
      &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        display: block;
        width: 5px;
        height: 5px;
        background-color: $color-links;
        @include border-radius(50%);
      }
    }
  }
  ol ul, ol ol, ul ul, ul ol {
    margin-top: 20px;
  }
  table {
    margin: 10px 0 30px !important;
    font-size: 17px;
    line-height: 27px;
    font-weight: 300;
  }
  &.article-bigger {
    h2 {
      font-size: 34px;
    }
    h3 {
      font-size: 30px;
    }
    h4 {
      font-size: 27px;
    }
    h5 {
      font-size: 22px;
    }
    p, ul li, ol li {
      font-size: 21px !important;
    }
    table {
      font-size: 19px;
    }
  }
  &.article-biggest {
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 29px;
    }
    h5 {
      font-size: 24px;
    }
    p, ul li, ol li {
      font-size: 23px !important;
    }
    table {
      font-size: 21px;
    }
  }
}
@media only screen and (max-width: 1429px) {
  article {
    p, ol li, ul li,
    .card-body article p, .card-body article ol li, .card-body article ul li {
      font-size: 17px !important;
      line-height: 32px !important;
    }
  }
}

@media only screen and (max-width: 1199px) {
  article {
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
    h3 {
      font-size: 26px;
    }
    h4 {
      font-size: 22px;
    }
    h5 {
      font-size: 18px;
    }
  }
  .card-body article {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 991px) {
  article {
    h2 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
    }
    h3 {
      font-size: 22px;
      margin: 0 0 20px;
      padding: 15px 0 0;
    }
    h4 {
      font-size: 20px;
      margin: 0 0 20px;
      padding: 15px 0 0;
    }
    h5 {
      font-size: 18px;
      margin: 20px 0;
    }
    p, table, ul li, ol li,
    .card-body article p, .card-body article ol li, .card-body article ul li {
      font-size: 16px !important;
      line-height: 30px !important;
    }
    ul li::before {
      top: 13px;
    }
    ol li {
      padding-left: 10px;
    }
  }
  .card-body article {
    h2 {
      font-size: 22px;
      line-height: 26px;
    }
  }
}
@media only screen and (max-width: 767px) {
  article {
    h2 {
      font-size: 22px;
      line-height: 28px;
    }
		h3 {
			font-size: 20px;
		}
		h4 {
			font-size: 18px;
		}
		h5 {
			font-size: 17px;
		}
		p, ol li, ul li, table,
    .card-body article p, .card-body article ol li, .card-body article ul li {
			font-size: 15px !important;
			line-height: 26px !important;
		}
		p, ul, ol {
			margin-bottom: 15px;
		}
    ol li {
      margin-bottom: 15px;
    }
	}
  .card-body article {
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 15px;
    }
  }
}
@media only screen and (max-width: 575px) {
  article {
    p br {
      display: none;
    }
  }
}
@media only screen and (max-width: 479px) {
  article {
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
		h3 {
			padding-top: 0;
			margin-bottom: 15px;
			font-size: 18px;
		}
		h4 {
			padding-top: 0;
			margin-bottom: 15px;
			font-size: 16px;
		}
		h5 {
			padding-top: 0;
			margin-bottom: 15px;
			font-size: 15px;
		}
		ol {
			margin-left: 15px;
			li {
				padding-left: 8px;
        ul li {
          padding-left: 14px;
        }
			}
		}
		ul {
			margin-left: 0;
		}
	}
  .card-body article {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 15px;
    }
    h5 {
      font-size: 14px;
    }
  }
}

/*****************************************************************************
Forms
*****************************************************************************/
.form-simple {
  .form-group {
    margin-bottom: 25px;
  }
  .form-control {
    padding: 20px 30px;
    height: 49px;
    border-color: #c6c4c4;
    background-color: #fff;
    color: #666;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    @include border-radius(2px);
    &.is-invalid {
      border-color: #dc3545;
      background-image: none;
    }
    &:focus {
      border-color: $color-links;
      box-shadow: 0 0 0 0.2rem rgba(224, 29, 59, 0.3) !important;
    }
  }
  textarea.form-control {
    height: 200px;
  }
  .custom-checkbox {
    .custom-control-label {
      padding: 5px 0 0 5px;
      color: #818692;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
  }
  .custom-file {
    .custom-file-input {
      padding: 20px 18px !important;
      height: 49px !important;
      border-color: #c9cacf !important;
      background-color: #fafafa !important;
      color: #747272;
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      @include border-radius(2px);
    }
  }
  .file {
    margin-bottom: 20px;
    margin-left: 25px;
    .attach-file {
      padding: 0 0 0 31px;
      background: url(../img/icons/attach-file.png) no-repeat 0 0;
      color: #747272;
      line-height: 30px;
      font-size: 15px;
      font-weight: 400;
      @include transition(all 0.3s ease);
    }
  }
}
@media only screen and (max-width: 1199px) {
  .form-simple .form-group {
		margin-bottom: 15px;
	}
}
@media only screen and (max-width: 575px) {
  .form-simple .form-control {
    padding: 20px;
  }
}

/*****************************************************************************
                                 Menu Hamburger Styles
*****************************************************************************/

/* Icon 1 */

#nav-icon {
    width: 26px;
    height: 19px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

}

/* Icon 2 */

#nav-icon {
  display: none;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: #707e83;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 0px;
}

#nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 7px;
}

#nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    top: 14px;
}

#nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
    left: 5px;
    top: 7px;
}

#nav-icon.open span:nth-child(2) {
    left: calc(50% - 2px);
    top: 7px;
}

#nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

#nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

#nav-icon.open span:nth-child(5) {
    left: 5px;
    top: 13px;
}

#nav-icon.open span:nth-child(6) {
    left: calc(50% - 2px);
    top: 13px;
}
